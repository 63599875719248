import React from "react"
import { Helmet } from "react-helmet"
import * as Yup from "yup"
import { Formik, Form } from "formik"
import { PageProps } from "gatsby"

import { useAnalytics } from "../../hooks"

import {
  ButtonWithLoader,
  Checkbox,
  Loader,
  RadioButton,
} from "../../components"
import { registrantType, GatsbyLocationObjectType } from "../../types"

import BreezeLogo from "../../images/breeze-logo-full.inline.svg"
import TickIcon from "../../images/icons/verified--green.inline.svg"

const emptyRegistrant = {
  id: "",
  created: "",
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  placeOfWork: "",
  profession: "",
  courseCompleted: "",
  expirationDate: "",
}

type Props = PageProps & {
  location: GatsbyLocationObjectType
}

export default ({ location }: Props) => {
  const analytics = useAnalytics()
  analytics.track(["User", "Page View"], {
    page: "/register/continue-subscription/",
  })

  const [needsToUpdate, setNeedsToUpdate] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [errorGet, setErrorGet] = React.useState("")
  const [errorContinue, setErrorContinue] = React.useState("")
  const [registrantId, setRegistrantId] = React.useState("")
  const [registrant, setRegistrant] =
    React.useState<registrantType>(emptyRegistrant)
  const [completed, setCompleted] = React.useState(false)

  const handleSubmit = async (
    values: {
      criminalConvictions: boolean
      safeStandards: boolean
      continuousCpd: boolean
      cpdEvidence: boolean
    },
    {
      setSubmitting,
    }: {
      setSubmitting: (arg0: boolean) => void
    }
  ) => {
    try {
      const response = await fetch(
        `${process.env.GATSBY_API_BASE_URL}/register/continue-subscription`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            id: registrantId,
          }),
        }
      )
      if (response.status === 200) {
        setCompleted(true)
        setSubmitting(false)
      } else {
        throw "Could not continue register membership, subscription has not expired yet or expiration date could not be found"
      }
    } catch (error) {
      if (typeof error === "string") {
        setErrorContinue(error)
      } else {
        setErrorContinue(
          "Could not continue register membership, error unreadable"
        )
      }
    }
  }

  React.useEffect(() => {
    const getRegistrantId = async ({
      locationSearch,
    }: {
      locationSearch: string
    }) => {
      try {
        const queryParams = locationSearch.split("?id=")
        const registrantId = queryParams[1]
        setRegistrantId(registrantId)
      } catch (error) {
        setErrorGet("Could not get registrant Id")
        setLoading(false)
      }
    }
    getRegistrantId({ locationSearch: location.search })
  }, [])

  React.useEffect(() => {
    const getRegistrantDetails = async () => {
      setLoading(true)
      setErrorGet("")
      try {
        const response = await fetch(
          `${process.env.GATSBY_API_BASE_URL}/register/subscriber-details/?id=${registrantId}`,
          {
            method: "GET",
          }
        )

        if (response.status === 200) {
          const data = await response.json()
          setRegistrant(data)
        } else {
          throw "Could not get registrant details, registrant not found"
        }
      } catch (error) {
        if (typeof error === "string") {
          setErrorGet(error)
        } else {
          setErrorGet("Could not get registrant details, error unreadable")
        }
      } finally {
        setLoading(false)
      }
    }
    if (registrantId) {
      getRegistrantDetails()
    } else {
      setErrorGet("Could not get registrant Id")
    }
  }, [registrantId])

  return completed ? (
    <div className="wrapperWidth checkoutSucceeded">
      <Helmet title="Register Membership Continued | Breeze Academy" />
      <div className="checkoutSucceededIcon">
        <TickIcon />
      </div>
      <h1 className="checkoutHeading">Register membership continued</h1>
      <p className="checkoutSucceededDesc">
        You can now close this screen. Thank you for being part of the Breeze
        Academy register.
      </p>
    </div>
  ) : (
    <Formik
      initialValues={{
        criminalConvictions: false,
        safeStandards: false,
        continuousCpd: false,
        cpdEvidence: false,
      }}
      validationSchema={Yup.object().shape({
        criminalConvictions: Yup.boolean().oneOf(
          [true],
          "Please accept to stay on the Breeze Academy Register"
        ),
        safeStandards: Yup.boolean().oneOf(
          [true],
          "Please accept to stay on the Breeze Academy Register"
        ),
        continuousCpd: Yup.boolean().oneOf(
          [true],
          "Please accept to stay on the Breeze Academy Register"
        ),
        cpdEvidence: Yup.boolean().oneOf(
          [true],
          "Please accept to stay on the Breeze Academy Register"
        ),
      })}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form className="wrapperWidth pt-8 pb-8 checkoutWrapper">
          <Helmet title="Keep Your Space on the Breeze Academy Register | Breeze Academy" />
          <div className="mb-4">
            <BreezeLogo />
          </div>
          <h1 className="headingExtraSmall mb-6">
            Keep your space on the Breeze Academy register
          </h1>
          <div className="continueSubscriptionTable">
            {loading || errorGet !== "" ? (
              <Loader
                content="Getting your registrant details"
                errors={errorGet ? [errorGet] : []}
              />
            ) : (
              <>
                <label className="mb-4">Your information:</label>
                <table className="registerResultsTable registerResultsTableVertical">
                  <tbody>
                    <tr>
                      <td className="registerResultsTableHeadCell">
                        Registration no.
                      </td>
                      <td className="registerResultsTableBodyCell">
                        {registrantId}
                      </td>
                    </tr>
                    <tr>
                      <td className="registerResultsTableHeadCell">
                        First name
                      </td>
                      <td className="registerResultsTableBodyCell">
                        {registrant.firstName}
                      </td>
                    </tr>
                    <tr>
                      <td className="registerResultsTableHeadCell">
                        Last name
                      </td>
                      <td className="registerResultsTableBodyCell">
                        {registrant.lastName}
                      </td>
                    </tr>
                    <tr>
                      <td className="registerResultsTableHeadCell">
                        Profession
                      </td>
                      <td className="registerResultsTableBodyCell">
                        {registrant.profession}
                      </td>
                    </tr>
                    <tr>
                      <td className="registerResultsTableHeadCell">
                        Place of work
                      </td>
                      <td className="registerResultsTableBodyCell">
                        {registrant.placeOfWork}
                      </td>
                    </tr>
                    <tr>
                      <td className="registerResultsTableHeadCell">
                        {" "}
                        Breeze courses completed
                      </td>
                      <td className="registerResultsTableBodyCell">
                        {registrant.courseCompleted}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            )}
          </div>
          <label className="mt-8 mb-3">
            Do you need to update any information above?
          </label>
          <div className="radioButtons radioButtonsPayments mb-8">
            <RadioButton
              name="No"
              selected={!needsToUpdate}
              onClick={() => setNeedsToUpdate(false)}
            />
            <RadioButton
              name="I need to update"
              selected={needsToUpdate}
              onClick={() => setNeedsToUpdate(true)}
            />
          </div>
          {needsToUpdate && (
            <div className="formGuidance mb-8">
              <span>
                Drop us an email at{" "}
                <a
                  className="link"
                  href="mailto:enquiries@breeze.academy"
                  target="_blank"
                  rel="noopener"
                >
                  enquiries@breeze.academy
                </a>{" "}
              </span>
            </div>
          )}
          <div>
            <label className="mb-4">Breeze Academy register declarations</label>
            <Checkbox
              name="criminalConvictions"
              label="In the last year, I have NOT received a criminal conviction that poses a threat to the general public or the integrity of healthcare professionals"
            />
            <Checkbox
              name="safeStandards"
              label="In the last year I have maintained, and in the next year I will continue to practice, in line with the Breeze Academy ‘Standards of safe, effective and professional practice’"
            />
            <Checkbox
              name="continuousCpd"
              label="In the last year I have maintained, and in the next year I will continue to ensure my CPD is continuous, varied, and directly related to my area of clinical work "
            />
            <Checkbox
              name="cpdEvidence"
              label="In the last year I have maintained, and in the next year I will continue to maintain an accurate, evidence-based record of my CPD"
            />
          </div>
          <div className="formGuidance mb-8">
            <span>
              Need to refresh your CPD?{" "}
              <a
                className="link"
                href="https://breeze.academy"
                target="_blank"
                rel="noopener"
              >
                Browse our courses
              </a>{" "}
            </span>
          </div>
          <ButtonWithLoader
            type="submit"
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Continue register membership
          </ButtonWithLoader>
          {errorContinue && <span className="errorText">{errorContinue}</span>}
          <p className="smallText mt-4 mb-0">
            By clicking the button above you’ll keep your place on
            breeze.academy/register
          </p>
        </Form>
      )}
    </Formik>
  )
}
